import { TransitInfo, CapacityInfo } from '../UI'
import { BRAND, IMGIX, IMGIX_SETTINGS } from '../../constants'
import * as Sentry from '@sentry/nextjs'
import Image from 'next/image'
import { externalImageLoader } from '../../utilities/externalImageLoader'

const { media, primaryColour } = BRAND

export const Card = ({ space, button, locale }) => {
  const { id, tour_url, venue, headbox_url } = space

  const images = Object.values(space.photos)[0][0]

  if (!images?.length) {
    Sentry.withScope((scope) => {
      scope.setExtra('message', `No photos found for space. ILP preview card skipped`)
      scope.setExtra('id', id)
      scope.setExtra('url', headbox_url)
    })
    return null
  }

  images.sort((a, b) => a.position - b.position)

  const featureImage = images[0]?.s3_key

  const getLinkToSpaceWithLocale = (url, locale) => {
    let linkToSpace = url.replace('---', '-').replace('--', '-')
    linkToSpace = linkToSpace.endsWith('-') ? linkToSpace.slice(0, -1) : linkToSpace

    return locale === 'GB'
      ? `${process.env.NEXT_PUBLIC_HEADBOX_ENDPOINT}${linkToSpace}`
      : `${process.env.NEXT_PUBLIC_HEADBOX_ENDPOINT}/${locale.toLowerCase()}${linkToSpace}`
  }

  return (
    <a className='card' href={getLinkToSpaceWithLocale(headbox_url, locale)} target='_blank'>
      <section style={{ position: 'relative' }}>
        {tour_url && (
          <label className='label primary'>
            <Image alt='HeadBox 3D Tour of the venues Icon' src='/images/3Dtour.png' height={12} width={10} />
            <span className='primary' style={{ marginLeft: '4px' }}>
              {button[0].cta}
            </span>
          </label>
        )}
        {venue.power_host_tier > 0 && <label className='label secondary'>{button[1].cta}</label>}
        <div className='image-container'>
          <Image
            className='image'
            loader={externalImageLoader}
            unoptimized
            src={IMGIX + featureImage + IMGIX_SETTINGS}
            aria-hidden={true}
            fill
            style={{ objectFit: 'cover' }}
            sizes='(max-width: 600px) 500px, 400px'
          />
        </div>
      </section>
      <section className='post'>
        <h5 className='title'>{`${venue.name} - ${space.name}`}</h5>
        <section className='row'>
          <TransitInfo transit={venue.nearest_transit} locale={locale} />
          <CapacityInfo capacity={space.capacity} />
        </section>
      </section>
      <style jsx>{`
        .3d-tour-icon {
          margin-right: 4px;
        }

        .card {
          flex: 1 1 0;
          max-width: 395px;
          min-width: 350px;
          border-radius: 8px;
          box-shadow: none;
          background-color: transparent;
          text-decoration: none;
          display: flex;
          flex-direction: column;
          margin-bottom: 16px;
        }

        .label {
          position: absolute;
          height: 24px;
          top: 16px;
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 12px;
          font-weight: 500;
          padding: 3px 6px;
          border-radius: 4px;
          z-index: 10;
        }

        .primary {
          background-color: ${primaryColour};
          color: white;
          left: 16px;
        }

        .secondary {
          background-color: white;
          color: ${primaryColour};
          right: 16px;
        }

        .image-container {
          width: 100%;
          height: 255px;
          border-radius: 8px;
          object-fit: cover;
          min-height: 255px;
          overflow: hidden;
          position: relative;
          margin-bottom: 8px;
        }

        .row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
        }

        .toggle {
          height: 25px;
          color: ${primaryColour};
          background-color: white;
          border: 1px solid ${primaryColour};
          font-size: 12px;
          padding: 3px 6px;
          cursor: pointer;
          border-radius: 2px;
          margin: 5px;
        }

        .title {
          font-weight: 600;
          padding-right: 6px;
          font-size: 18px;
          line-height: 24px;
          padding-bottom: 4px;
          text-align: left;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        @media screen and (max-width: ${media.medium}px) {
          .card {
            min-width: 260px;
            max-width: 332px;
          }
        }
        @media screen and (min-width: 568px) and (max-width: 880px) {
        .card:last-child {
          width: calc((100% - 16px) / 2);
        }
      }
        @media screen and (max-width: ${media.small}px) {
          .title {
            font-size: 16px;
          }
          .card {
            width: 100%;
            max-width: initial;
            min-width: initial;
          }
        }
      }
      `}</style>
    </a>
  )
}
