import { BRAND } from '../../constants'
import { externalImageLoader } from '../../utilities/externalImageLoader'
import { ButtonLink } from '../UI'
import { STRAPI_IMGX } from '../../constants'
import Image from 'next/image'
import CTAButtons from './CTAButtons'
import { useState } from 'react'

const layout = {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: 24,
  textWrap: 'balance'
}

const renderImage = (heroBackgroundImage) => {
  return `${STRAPI_IMGX}/${
    heroBackgroundImage.hash + heroBackgroundImage.ext
  }?auto=format&h=500&w=1500&fit=crop`
}

const Hero = ({ homePage, callToActionURL }) => {
  const {
    heroBackgroundImage,
    heroBackgroundVideo,
    heroTrustBannerBlurb,
    heroTrustBannerLogo,
    heroBlurb,
    heroHeadline
  } = homePage

  const sortedHeroTrustBannerLogo = heroTrustBannerLogo.sort((a, b) => a.order - b.order)

  const [isVideoError, setVideoError] = useState(false)

  return (
    <header style={{ position: 'relative', overflow: 'hidden', width: '100%' }}>
      <section className='vignetteTop' />
      <section className='standfirst-section'>
        <div style={layout}>
          <h1 className='standfirst'>
            {heroHeadline === 'Events made easy.' ? (
              <>
                Events made <br /> easy.
              </>
            ) : (
              heroHeadline
            )}
          </h1>
          {heroBlurb && <p className='subheader'>{heroBlurb}</p>}
          <section className='vignetteBottom'>
            <CTAButtons homePage={homePage} />
            <section className='trust-banner'>
              {heroTrustBannerBlurb.map((blurb) => (
                <div className='banner-item'>
                  <Image
                    alt='Events made easy at HeadBox'
                    src={blurb.url}
                    aria-hidden='true'
                    width={20}
                    height={20}
                    priority={true}
                    className='img'
                  />{' '}
                  <text>{blurb.blurb_text}</text>
                </div>
              ))}
            </section>
            <section className='logo-container'>
              <div className='logo-scroll'>
                <div className='logo-scroll__wrapper'>
                  {sortedHeroTrustBannerLogo.map((logo) => (
                    <div className='trust-logo-item'>
                      <Image
                        alt='Events made easy at HeadBox'
                        src={renderImage(logo)}
                        loader={externalImageLoader}
                        width={100}
                        height={60}
                        priority={true}
                        className='img'
                      />
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </section>
        </div>
      </section>
      <div className='hero-wrapper'>
        {heroBackgroundVideo && !isVideoError ? (
          <video style={{ minWidth: '100%', minHeight: '100%', objectFit: 'cover' }} autoPlay muted loop>
            <source
              src={renderImage(heroBackgroundVideo)}
              type='video/mp4'
              onError={() => setVideoError(true)}
            />
          </video>
        ) : (
          <Image
            alt='Events made easy at HeadBox'
            fill
            src={renderImage(heroBackgroundImage)}
            loader={externalImageLoader}
            aria-hidden='true'
            style={{ objectFit: 'cover' }}
            priority={true}
            className='img'
          />
        )}
      </div>
      <style jsx>{`
        .logo-container {
          display: flex;               /* Aligns children in a row */
          flex-direction: column;      /* Stacks content vertically */
          gap: var(--gap);             /* Adds space between child elements */
          margin: auto;                /* Centers container horizontally */
          max-width: 70%;            /* Limits width to the viewport */
          padding: 64px 0px 24px 0px;
        }

        /* Scrolling area */
        .logo-scroll {
          display: flex;               /* Aligns logos in a horizontal row */
          overflow: auto;            /* Hides overflow to create infinite scroll effect */
          flex-wrap: nowrap; 
          user-select: none;           /* Disables text selection */
          gap: var(--gap);             /* Space between logos */
        }

        .logo-scroll__wrapper {
          flex-shrink: 0;              /* Prevents wrapper from shrinking */
          display: flex;               /* Aligns logos horizontally */
          align-items: center;         /* Centers logos vertically */
          justify-content: space-around; /* Distributes logos evenly */
          gap: var(--gap);             /* Adds spacing between logos */
          min-width: 100%;             /* Wrapper width covers full viewport */
        }

        .logo-scroll__wrapper:nth-child(even) {
            margin-left: calc(var(--logo-width) / -2); /* Offsets even wrappers for smooth scroll overlap */
          }

        .logo-scroll::-webkit-scrollbar {
          display: none;
        }
          
        .trust-logo-item {
          padding: 0px 48px 0px 0px;
        }

        .buttons {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          margin: 0px 20px 20px 20px;
        }

        .trust-banner {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          color: white;
          gap: 20px;
          padding: 64px 20px 0px 20px;
        }

        .banner-item {
          display: flex;
          gap: 4px;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          color: white;
        }

        .vignetteTop {
          position: absolute;
          z-index: 20;
          width: 100%;
          height: 194px;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
        }

        .vignetteBottom {
          width: 100%;
          height: 100%;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
        }

        .trust-item {
          margin: 0px 16px 16px 0px;
        }

        .hero-wrapper {
          position: absolute;
          object-fit: cover;
          width: 100%;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
        .standfirst-section {
          position: relative;
          height: 100%;
          width: 100%;
          display: flex;
          font-weight: bold;
          justify-content: center;
          -webkit-justify-content: center;
          flex-direction: column;
          z-index: 20;
        }
        .standfirst {
          color: white;
          text-align: center;
          font-size: 64px;
          line-height: 68px;
          padding-top: 15vh;
          max-width: 840px;
          text-wrap-style: auto;
        }
        .subheader {
          color: white;
          text-align: center;
          font-weight: 400;
          padding-top: 16px;
          font-size: 18px;
          max-width: 540px;
        }
        .cta-title {
          color: white;
          text-align: center;
          margin-top: 24px;
        }

        @media screen and (max-width: 1050px) {
          .logo-scroll {
            mask-image: linear-gradient(
              to right,
              hsl(0 0% 0% / 0),
              hsl(0 0% 0% / 1) 30%,
              hsl(0 0% 0% / 1) 70%,
              hsl(0 0% 0% / 0)
            );                         
          }

        @media screen and (max-width: 800px) {
          .standfirst {
            font-size: 46px;
            padding-top: 124px;
            max-width: 700px;
          }

          .subheader {
            font-size: 14px;
          }

          .logo-container {
            max-width: 90vw;            /* Limits width to the viewport */
          }

          .trust-banner {
            gap: 10px;
          }
        }

        @media screen and (max-width: 550px) {
        
          .standfirst {
            font-size: 32px;
            padding-top: 116px;
            line-height: 40px;
            max-width: 400px;
          }

        .logo-container {
          padding: 24px 0px 24px 0px;
        }
  

          .subheader {
            font-size: 18px;
          }
          .trust-banner {
            gap: 8px;
            padding: 20px;
          }

          .trust-logo {
            gap: 5px;
            padding: 20px;
          }
      `}</style>
    </header>
  )
}

export default Hero
