import { BRAND } from '../../constants'
import Modal from './ModalWrapper'

const StandfirstModal = () => (
  <Modal>
    <div className='container'
    >
      <h2 className='h2'>
        HeadBox is <i className='i'>the</i> easy way to plan meetings and events with{' '}
        <span className='span'>confidence. </span>
      </h2>
      <br />
      <h5 className='h5'>
        Book, manage and deliver everything in one place.
        <br />
        No long searches, no hidden costs, no stress.
      </h5>
    </div>
    <style jsx>{`
      .i {
        font-size: 40px;
      }
      .span {
        color: ${BRAND.primaryColour};
        font-size: 40px;
        width: 100%;
      }
      .container {
        margin: 50px 0px;
        max-width: 880px;
        padding: 0 32px;
        display: flex;
        flex-direction: column;
      }
      .h2 {
        text-align: center;
        width: 100%;
        font-size: 40px;
        font-color: #1f1f1f;
      }
      .h5 {
        text-align: center;
        width: 100%;
        font-size: 22px;
        line-height: 1.5;
        padding: 2px;
      }
      @media screen and (max-width: ${BRAND.media.small}px) {
        .container {
          margin: 16px 0px;
        }
        .h2 {
          font-size: 34px;
        }
        .h5 {
          margin: 0 auto;
          max-width: ${BRAND.layout.minWidth}px;
        }
        .span {
          font-size: 34px;
        }
        .i {
          font-size: 34px;
        }
      }
    `}</style>
  </Modal>
)

export default StandfirstModal
