import React from 'react'
import { Logo } from '../UI'
import { GdprLogo, IsoLogo } from '../Images'
import { FOOTER_STYLE as s } from './style'
import { BRAND } from '../../constants'

const { media } = BRAND

const Links = ({ title, links, children }) => {
  return (
    <section className='link-block'>
      <div className='text-container'>
        <h5 className='h5'>{title}</h5>
        <br />
        {links && (
          <ul aria-label={title} className='ul'>
            {links.map((l, i) => (
              <li key={`link-${i}`}>
                <a href={l.url} className='a'>
                  {l.title}
                </a>
              </li>
            ))}
          </ul>
        )}
        {children}
      </div>
      <style jsx>{`
        .link-block {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        ul {
          list-style: none;
          padding: 0;
          color: #4a4c49;
        }

        li {
          margin-bottom: 14px;
        }

        .h5 {
          font-weight: bold;
          font-size: 22px;
          line-height: 1.5;
          color: #4a4c49;
          white-space: nowrap;
        }
        .a {
          text-decoration: none;
          font-size: 16px;
          white-space: nowrap;
        }

        @media screen and (max-width: 500px) {
          .link-block {
            align-items: flex-start;
          }
        }
      `}</style>
    </section>
  )
}

const Footer = ({ footer }) => {
  const {
    copyright_text,
    link_header_1,
    link_header_2,
    link_section_1,
    link_section_2,
    social_links_header,
    social_links
  } = footer

  return (
    <footer style={s.footer}>
      <section className='links'>
        <div className='link-row'>
          <section className='logo-section'>
            <div>
              <Logo fill={'grey'} />
              <h5
                style={{ color: '#4a4c49', fontWeight: '400', padding: '20px 0px', whiteSpace: 'pre-line' }}>
                {JSON.parse(copyright_text)}
              </h5>
              <section className='icons'>
                <GdprLogo />
                <a href='/en/cp/iso'>
                  <IsoLogo />
                </a>
              </section>
            </div>
          </section>
          <Links links={link_section_1} title={link_header_1} />
        </div>
        <div className='link-row'>
          <Links links={link_section_2} title={link_header_2} />
          <Links links={social_links} title={social_links_header} />
        </div>
      </section>
      <style jsx>{`
        .logo-section {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .links {
          width: ${BRAND.layout.content}px;
          max-width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: flex-start;
          gap: 32px;
        }

        .link-row {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          flex: 1;
          gap: 32px;
          flex-wrap: nowrap;
        }

        .icons {
          display: flex;
          justify-content: space-between;
          width: 170px;
        }

        @media screen and (max-width: ${media.small + 150}px) {
          .links {
            flex-wrap: wrap;
            justify-content: space-between;
          }
        }

        @media screen and (max-width: 500px) {
          .links {
            flex-direction: column;
          }
          .link-row {
            flex-direction: column;
          }
        }
      `}</style>
    </footer>
  )
}

export default Footer
