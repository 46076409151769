import { BRAND } from '../../constants'
import CTAButtons from '../HomePage/CTAButtons'

const { media, black, white } = BRAND
const MAX_CHARS = 110

export const PrimaryCTA = ({ primaryCta, callToActionURL, homePage, blurbCta }) => {
  const { cta, title, blurb } = primaryCta

  return (
    <div className='card'>
      <h3 className='heading'>{blurbCta ? blurbCta : title}</h3>
      {!blurbCta && (
        <h3 className='paragraph'>{blurb.length > MAX_CHARS ? `${blurb.substr(0, MAX_CHARS)}...` : blurb}</h3>
      )}
      <div>
        {callToActionURL ? (
          <a className='button' id='#primary-cta' href={callToActionURL}>
            {cta}
          </a>
        ) : (
          <div className='ctaPadding'>
            <CTAButtons homePage={homePage} />
          </div>
        )}
      </div>
      <style jsx>{`
        .heading {
          font-weight: bold;
          font-size: 40px;
          line-height: 52px;
          text-align: center;
          font-color: white;
          color: white;
          margin: 16px;
          max-width: 650px;
        }
        .paragraph {
          font-size: 18px;
          max-width: 550px;
          color: white;
          line-height: 24px;
          margin: 16px 0 32px;
          text-align: center;
          font-weight: 600;
        }
        .button {
          background-color: ${white};
          padding: 10px 20px;
          margin: 20px;
          color: ${black};
          border-radius: 4px;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          cursor: pointer;
          text-decoration: none;
          text-align: center;
        }
        .card {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          min-height: 500px;
          color: white;
          background: linear-gradient(142.83deg, #af231c 15.51%, #5c110e 100%), #ffffff;
        }

        .ctaPadding {
          padding-bottom: 16px;
        }
        @media screen and (max-width: ${media.average}px) {
          .heading {
            font-size: 38px;
          }
        }
        @media screen and (max-width: ${media.small}px) {
          .heading {
            font-size: 24px;
            max-width: 400px;
            line-height: 24px;
          }
        }
      `}</style>
    </div>
  )
}
