import { BRAND } from '../../../constants'
import { TitleWithHighlight } from '../../UI'
import { insertDynamicText } from '../../../controllers/helpers'
import ReactMarkdown from 'react-markdown'
import { useRouter } from 'next/router'
import Post from './Post'
import WrittenBy from './WrittenBy'
import SecondaryHowItWorks from './SecondaryHowItWorks'
import rehypeRaw from 'rehype-raw'
import { addAnchors } from './addAnchors'
const { media } = BRAND

export const listicleMarkdownComponents = {
  img: ({ node, ...rest }) => <img style={{ maxWidth: '100%', borderRadius: '8px' }} {...rest} />,
  li: ({ node, ...rest }) => (
    <li
      style={{ color: '#4a4c49', fontSize: '16px', lineHeight: 1.7, fontWeight: 500, textAlign: 'left' }}
      {...rest}
    />
  ),
  a: ({ node, ...rest }) => <a style={{ color: '#AF231C' }} {...rest} />
}

export const Listicle = ({
  listicle,
  howItWorks,
  displayLocation,
  nicheOrEventName,
  callToActionURL,
  locale,
  baseUrl
}) => {
  const { query } = useRouter()

  let { created_at, updated_at, title, introduction, venues, author, blog_article } = listicle || {}
  title = insertDynamicText(title, displayLocation, nicheOrEventName)
  const listicleDate = updated_at || created_at

  if (query.page > 1) {
    return null
  }

  introduction = introduction.split(/\n(?!(\s*-|\s*\d+\.))/g).filter((e) => e)
  return (
    <section className={`margin ${listicle.blog_article ? 'blog-article' : ''}`}>
      {author.length > 0 && <WrittenBy {...{ author, locale, listicleDate, baseUrl }} />}
      <br />
      <div>
        <TitleWithHighlight string={title} highlight={{ displayLocation, nicheOrEventName }} h1 />
      </div>

      <section className='standfirst'>
        {introduction.map((string, i) => (
          <div key={`section-${i}`}>
            <ReactMarkdown
              components={listicleMarkdownComponents}
              className='markdown'
              rehypePlugins={[rehypeRaw, addAnchors]}>
              {string}
            </ReactMarkdown>
            <br />
          </div>
        ))}
      </section>
      {!listicle.blog_article && (
        <SecondaryHowItWorks howItWorks={howItWorks} callToActionURL={callToActionURL} />
      )}
      {venues.map((v, i) => (
        <Post key={`post-${i}`} {...{ v, i, callToActionURL, locale, blog_article }} />
      ))}
      <style jsx>{`
        .blog-article p,
        .blog-article .standfirst {
          text-align: left;
        }

        .blog-article p:has(img) {
          text-align: center;
        }

        .blog-article img {
          max-height: 30em;
        }

        .markdown h1::before,
        .markdown h2::before,
        .markdown h3::before,
        .markdown h4::before {
          /* Required to ensure anchor links use the correct vertical position */
          content: '';
          display: block;
          height: 75px;
          margin-top: -75px;
          visiblity: hidden;
        }

        .location {
          margin: 12px 0px;
        }
        .row {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 100%;
          margin: 6px 0px;
        }

        .margin {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 40px 16px 36px 16px;
          width: 100%;
        }

        .standfirst {
          font-weight: 200;
          font-size: 22px;
          max-width: 800px;
          text-align: center;
          margin: 8px 0px;
        }
        @media screen and (max-width: ${media.average}px) {
        }
        @media screen and (max-width: ${BRAND.media.tablet}px) {
          .reduce-margin {
            margin: -20px 0px;
          }
        }
      `}</style>
    </section>
  )
}
