import React from 'react'

import ArrowSvg from './ArrowSvg'

const { BASE_PATH, PRODUCT_BLOCK_LINK2 } = process.env

const VenueBanner = ({ link }) => {
  return (
    <>
      <div className='venue-banner'>
        <div className='venue-banner-text'>
          <h1 className='venue-banner-title-small'>HeadBox for Hosts</h1>
          <h1 className='venue-banner-title-large'>A venue platform designed for your growth</h1>
          <h1 className='venue-banner-subtitle'>
            HeadBox is free to list, easy to use, and trusted by corporate event bookers
          </h1>
          <a href={link ? link : BASE_PATH + PRODUCT_BLOCK_LINK2}>
            <button className='venue-banner-button'>
              Find out more{' '}
              <div style={{ paddingLeft: '4px', display: 'inline' }}>
                <ArrowSvg color='#595959' />
              </div>
            </button>
          </a>
        </div>
        <img
          className='venue-banner-image'
          aria-hidden={true}
          height='342px'
          width='642px'
          src={`/ilp-public/assets/images/headboxVenues.png`}
        />
      </div>
      <style jsx>{`
        .venue-banner {
          position: relative;
          width: 90%;
          max-width: 1216px;
          height: 395px;
          border-radius: 12px;
          background: linear-gradient(to right, rgba(122, 25, 20, 1), rgba(175, 35, 28, 1));
          padding: 48px 32px 32px 32px;
          margin: 40px;
          opacity: 1;
          overflow: hidden;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 16px;
        }

        .venue-banner-text {
          display: flex;
          flex-direction: column;
          width: 40%;
        }

        .venue-banner-title-small {
          font-family: Montserrat;
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
          text-align: left;
          color: white;
          padding-bottom: 2px;
          margin: 0;
        }

        .venue-banner-title-large {
          font-family: Montserrat;
          font-size: 36px;
          font-weight: 800;
          line-height: 40px;
          text-align: left;
          color: white;
          margin: 0;
          padding-bottom: 16px;
        }

        .venue-banner-subtitle {
          font-family: Montserrat;
          font-size: 18px;
          font-weight: 500;
          line-height: 28px;
          text-align: left;
          color: white;
          margin: 0;
          padding-bottom: 16px;
        }

        .venue-banner-button {
          width: 160px;
          height: 32px;
          padding: 5px 10px;
          border-radius: 4px;
          background-color: white;
          color: rgba(89, 89, 89, 1);
          border: none;
          cursor: pointer;
          font-family: Montserrat;
          font-size: 16px;
          font-weight: 500;
        }

        .venue-banner-image {
          position: absolute;
          bottom: -1px;
          right: -1px;
          max-width: 100%;
          max-height: 100%;
        }

        @media (max-width: 1135px) {
          .venue-banner {
            display: block;
            height: 625px;
            width: 91%;
            padding: 64px 0px 0px 64px;
          }

          .venue-banner-title-small {
            font-size: 18px;
          }

          .venue-banner-title-large {
            line-height: 40px;
            font-size: 36px;
          }

          .venue-banner-subtitle {
            font-size: 18px;
          }

          .venue-banner-text {
            width: 80%;
          }

          .venue-banner-image {
            position: absolute;
            bottom: calc(-130px + 0.01 * (100vw));
            right: -1px;
            width: 93%;
            height: auto;
            overflow: hidden;
          }
        }

        @media (max-width: 750px) {
          .venue-banner-image {
            position: absolute;
            bottom: -30px;
            right: -1px;
            width: 93%;
            height: auto;
            overflow: hidden;
          }
        }

        @media (max-width: 640px) {
          .venue-banner-image {
            bottom: -30px;
          }

          .venue-banner {
            display: block;
            height: 552px;
            padding: 32px 0px 0px 32px;
          }

          .venue-banner-title-small {
            font-size: 16px;
          }

          .venue-banner-title-large {
            line-height: 28px;
            font-size: 24px;
          }

          .venue-banner-subtitle {
            font-size: 16px;
          }
        }
      `}</style>
    </>
  )
}

export default VenueBanner
