import { BRAND, STRAPI_IMGX } from '../../constants'
import { externalImageLoader } from '../../utilities/externalImageLoader'
import { TitleWithHighlight } from '../UI'
import Image from 'next/image'

const Card = ({ card, target }) => {
  const WIDTH = 284
  const HEIGHT = WIDTH - 20
  const renderImage = (image) => {
    return `${STRAPI_IMGX}/${image?.hash + image?.ext}`
  }
  const alt = target === 'Venues' ? card.label : `Hire ${card.label} venues`
  return (
    <a className='card' style={{ textDecoration: 'none' }} key={card.label} href={card.url}>
      <div className='image-hero-wrapper'>
        <Image
          fill
          style={{ objectFit: 'cover' }}
          alt={alt}
          src={renderImage(card.cover[0])}
          loader={externalImageLoader}
          unoptimized
          sizes='350px'
        />
      </div>
      <h5 className='label'>{card.label}</h5>
      <style jsx>{`
        .card {
          max-width: 292px;
          min-width: 260px;
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 8px;
          margin-bottom: 16px;
        }

        .image-hero-wrapper {
          height: 265px;
          width: 100%;
          object-fit: cover;
          border-radius: 8px;
          position: relative;
          overflow: hidden;
        }
        .label {
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
        }
        @media screen and (max-width: ${BRAND.media.medium}px) {
          .card {
            min-width: 260px;
            max-width: 332px;
            margin-bottom: 8px;
          }
        }
        @media screen and (max-width: ${BRAND.media.small}px) {
          .card {
            min-width: 160px;
            max-width: 210px;
            margin-bottom: 8px;
          }
          .image-hero-wrapper {
            height: 160px;
          }
        }

        @media screen and (max-width: 400px) {
          .card {
            max-width: 100%;
          }
        }
      `}</style>
    </a>
  )
}
const TargetContent = ({ target, highlight }) => {
  return (
    target && (
      <section className='content-container'>
        <TitleWithHighlight string={target.title} highlight={highlight} />
        {target.subtitle && target.subtitle.trim().length > 0 && (
          <h5 className='subHeading'>{target.subtitle}</h5>
        )}
        <section className='cards'>
          {target.blocks.map((card, index) => (
            <Card key={`card-${index}`} {...{ card, target: target.target }} />
          ))}
        </section>
        <section className='tags'>
          {target?.tags?.map((e) => (
            <a key={e.label} className='tag' href={e.url}>
              {e.label}
            </a>
          ))}
        </section>
        <style jsx>{`
          .heading {
            font-size: 40px;
            font-weight: 700;
            margin: 24px 0px;
          }
          .subHeading {
            margin-top: 16px;
            max-width: 1100px;
            font-weight: 500;
            text-align: center;
            color: #4a4c49;
          }
          .content-container {
            width: 100%;
            max-width: ${BRAND.layout.content}px;
            display: flex;
            align-items: center;
            flex-direction: column;
            margin: 32px 0px;
          }
          .cards {
            width: 100%;
            max-width: 1280px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
            gap: 16px;
            padding: 32px;
          }
          .tags {
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;
            gap: 16px;
            max-width: 1200px;
          }
          .tag {
            padding: 8px 16px;
            border-radius: 8px;
            border: 1px solid #595959;
            text-decoration: none;
            cursor: pointer;
          }
          @media screen and (max-width: ${BRAND.media.medium}px) {
          }
          @media screen and (max-width: ${BRAND.media.small}px) {
            .subHeading {
              font-size: 16px;
              margin: 8px;
            }
            .cards {
              padding: 32px 16px;
            }
            .modal {
              margin: 16px 0px;
            }
            .tag {
              font-size: 14px;
            }
            .subHeading {
              max-width: 480px;
            }
          }
        `}</style>
      </section>
    )
  )
}
export default TargetContent
