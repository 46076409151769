import Image from 'next/image'
import { externalImageLoader } from '../../../../utilities/externalImageLoader'
import { BRAND } from '../../../../constants'

export const PrimaryContentBlockCard = ({ listicle }) => {
  const { imgSrc, title, description, url, alt } = listicle

  return (
    <>
      <a href={url} className='item-link'>
        <div className='card'>
          <div className='preview-image-wrapper'>
            <Image
              aria-hidden={true}
              className='preview-image'
              src={imgSrc}
              alt={alt}
              loader={externalImageLoader}
              unoptimized
              style={{ objectFit: 'cover' }}
              fill
              sizes='500px'
            />
          </div>
          <article className='preview'>
            <h4 className='preview-title'>{title}</h4>
            <p className='preview-text'>{description}</p>
            <p className='read-more'>Read more</p>
          </article>
        </div>
      </a>
      <style jsx>{`
        .item-link:hover .read-more,
        .item-link:focus-visible .read-more {
          text-decoration: underline;
        }

        .item-link:hover .preview-image-wrapper,
        .item-link:focus-visible .preview-image-wrapper {
          opacity: 0.8;
          transition: opacity 0.2s ease-in-out;
          -moz-transition: opacity 0.2s ease-in-out;
          -webkit-transition: opacity 0.2s ease-in-out;
        }

        .item-link {
          text-decoration: none;
          width: 100%;
        }

        .item-link:focus-visible {
          outline: none;
        }

        .card {
          scroll-snap-align: start;
          display: flex;
          flex-direction: row-reverse;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%;
          height: 368px;
          background: linear-gradient(0deg, #ffffff, #ffffff), #262626;
          border-radius: 8px;
        }

        .preview {
          display: flex;
          flex-direction: column;
          gap: 8px;
          padding: 48px 24px;
          width: 100%;
          height: 100%;
          align-items: flex-start;
          flex: 1;
        }

        .preview-title {
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          min-height: 64px;
        }

        .preview-image-wrapper {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 0px 8px 8px 0px;
          overflow: hidden;
          position: relative;
          opacity: 1;
          transition: opacity 0.2s ease-in-out;
          -moz-transition: opacity 0.2s ease-in-out;
          -webkit-transition: opacity 0.2s ease-in-out;
          flex: 2;
        }

        .preview-text {
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 7;
          line-clamp: 7;
        }

        .read-more {
          font-weight: 700;
          text-align: center;
          margin-top: auto;
        }

        h4,
        p {
          color: #1f1f1f;
        }

        h4 {
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
          margin-top: 0;
        }

        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }

        @media screen and (max-width: ${BRAND.media.tablet}px) {
          .card {
          }

          .preview {
            flex: 2;
            padding: 32px 24px;
          }

          .preview-title {
            -webkit-line-clamp: 3;
            line-clamp: 3;
          }

          .preview-image-wrapper {
            flex: 3;
          }
        }

        @media screen and (max-width: ${BRAND.media.small}px) {
          .card {
            flex-direction: column;
            height: 500px;
          }

          .preview-image-wrapper {
            min-height: 276px;
            max-height: 276px;
            border-radius: 8px 8px 0px 0px;
          }

          .preview {
            flex: 1;
            padding: 24px;
          }

          .preview-title {
            min-height: initial;
          }

          .preview-text {
            -webkit-line-clamp: 3;
            line-clamp: 3;
          }

          .preview-image-wrapper {
            flex: 1;
          }

          .read-more {
            align-self: center;
          }

          h4 {
            font-size: 18px;
            line-height: 26px;
          }
        }
      `}</style>
    </>
  )
}
