import React from 'react'

const GlobeSvg = () => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M11.2616 9.75C11.1045 11.8993 10.2779 13.8507 9.0009 15.3828C8.99847 15.3798 8.996 15.3768 8.99353 15.3739L8.9706 9.75H11.2616ZM12.7651 9.75C12.6291 11.8872 11.9156 13.8638 10.7829 15.512C13.4124 14.7938 15.402 12.5255 15.7088 9.75H12.7651ZM15.7088 8.25H12.7651C12.6291 6.11278 11.9156 4.1362 10.7829 2.48797C13.4124 3.20623 15.402 5.47453 15.7088 8.25ZM17.25 9C17.25 13.556 13.557 17.2494 9.0012 17.25L8.99353 15.3739C8.9928 15.373 8.99206 15.3721 8.99133 15.3712L8.96925 9.75H6.73468C6.89907 11.8939 7.72294 13.8402 8.99133 15.3712L8.99871 17.25C4.44303 17.2492 0.75 13.5559 0.75 9C0.75 4.48462 4.37752 0.816491 8.8773 0.750901C8.89615 0.749981 8.91503 0.749761 8.9339 0.750261L8.94117 2.60103C7.6839 4.14388 6.87449 6.09948 6.72826 8.25H8.96336L8.94117 2.60103C8.94107 2.60114 8.94126 2.60091 8.94117 2.60103L8.96448 8.25H11.2616C11.103 6.08 10.262 4.11177 8.96399 2.57313C8.95646 2.5823 8.94866 2.59183 8.94117 2.60103L8.9339 0.750261L8.99854 0.750001H9.0016C13.5572 0.750881 17.25 4.44419 17.25 9ZM5.22522 8.25C5.35126 6.12562 6.04253 4.15731 7.14676 2.50759C4.55218 3.2468 2.59527 5.49932 2.2912 8.25H5.22522ZM2.2912 9.75C2.59816 12.5268 4.58956 14.796 7.22098 15.5131C6.08918 13.8641 5.37377 11.8875 5.23083 9.75H2.2912Z'
        fill='#BFBFBF'
      />
    </svg>
  )
}

export default GlobeSvg
