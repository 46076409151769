import { BRAND } from '../../constants'
import { ButtonLink } from '../UI'

const WideModal = ({ linkTo, ctaLabel, img, heading, height }) => (
  <section className='relative-container'>
    <section className='modal'>
      <div className='side'>
        <h3 className='text'>{heading}</h3>
        <ButtonLink home href={linkTo}>
          {ctaLabel}
        </ButtonLink>
      </div>
    </section>
    <style jsx>{`
      .relative-container {
        border-radius: 16px;
        background-image: url("${img}");
        background-position: left center; 
        background-repeat: no-repeat; 
        background-size: cover;
        margin-left: -5px;
        width: calc(100% + 10px);
        position: relative;
        margin-bottom: 40px;
        padding: 32px;
        height: ${height ? `${height}px` : 'auto'};
      }
      .modal {
        width: 100%;
        height: 100%;
        max-width: 500px;
      }
      .side {
        height: 100%;
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
      .text {
        text-align: left;
        color: white;
        line-height: 1.2;
        font-size: 40px;
        margin-left: 16px;
      }
      @media screen and (max-width: ${BRAND.media.average}px) {
        .relative-container {
          padding: 24px;
        }
        .modal {
          max-width: 393px;
        }
        .text {
          text-align: left;
          margin-left: 16px;
        }
      }

      @media screen and (max-width: ${BRAND.media.small}px) {
        .relative-container {
          border-radius: 0;
        }
        .text {
          font-size: 30px;
        }
      }
    `}</style>
  </section>
)

export default WideModal
