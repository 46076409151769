import { useEffect } from 'react'
import Cookies from 'js-cookie'

export const useContentPageTracking = () => {
  useOnConsentGiven((p) => {
    if (!hasUserAllowedAnayliticsCookies(p)) return
    const location = window.location.href
    const contentPage = location.split('headbox.com').pop()
    const contentPageHistoryCookie = Cookies.get('_contentPageHistory')
    const contentPageHistory = contentPageHistoryCookie ? JSON.parse(contentPageHistoryCookie) : []
    if (contentPageHistory[0] == contentPage) return // No need to track going to the same page multiple times
    const updatedContentPageHistory = [contentPage, ...contentPageHistory.slice(0, 4)]
    Cookies.set('_contentPageHistory', JSON.stringify(updatedContentPageHistory), { domain: '.headbox.com' })
  }) // Empty dependency array means this effect runs once on mount
}
function hasUserAllowedAnayliticsCookies() {
  // This is just the variable set by iubenda
  // See: https://www.iubenda.com/en/help/1177-cookie-solution-getting-started
  return _iub?.cs?.consent?.purposes?.[4] === true
}

function useOnConsentGiven(callback) {
  useEffect(() => {
    let unmounted = false

    window.addEventListener(
      'iubConsentPromiseLoaded',
      () => {
        /* Globally defined in src/pages/_document.js */
        _iubConsentPromise.then(() => {
          if (unmounted) return
          callback()
        })
      },
      false
    )
    return () => {
      unmounted = true
    }
  }, [])
}
