import { BRAND } from '../../constants'
import { ErrorMessage } from '../UI'
import { normaliseLink } from '../../controllers/helpers/normaliseLink'
import _ from 'lodash'
import { useRef, useState } from 'react'
import { useClickOutside } from '../../hooks/useClickOutside'

const SectionTitle = ({ title, handleClick, activeMegaMenu, isHomepage }) => (
  <span
    role='button'
    onClick={(e) => {
      e.stopPropagation()
      handleClick()
    }}>
    <h5 className={activeMegaMenu ? 'category-selected' : 'category'}>{title}</h5>
    <style jsx>
      {`
        .category {
          font-size: 14px;
          font-weight: 500;
          cursor: pointer;
          line-height: 22px;
          padding: 8px 0px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          white-space: nowrap;
          z-index: 100000;
          color: ${isHomepage ? 'white' : 'inherit'};
        }

        .category-selected {
          font-size: 14px;
          font-weight: 500;
          cursor: pointer;
          line-height: 22px;
          padding: 8px 0px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          white-space: nowrap;
          z-index: 100000;
        }

        .category-selected:hover {
          color: ${BRAND.primaryColour};
          border-bottom: 2px solid ${BRAND.primaryColour};
          height: 22px;
          z-index: 1000;
        }

        .category:hover {
          border-bottom: 2px solid white;
          height: 22px;
          z-index: 1000;
        }
        .category-selected:active {
          color: ${BRAND.primaryColour};
          border-bottom: 2px solid ${BRAND.primaryColour};
          z-index: 10000000;
        }

        .category:active {
          border-bottom: 2px solid white;
          z-index: 10000000;
        }
      `}
    </style>
  </span>
)

const CloseTitle = ({ title }) => (
  <div>
    <section className='standfirst' id='stand-first'>
      <h5 className='title'>{title}</h5>
    </section>
    <style jsx>{`
      .close {
        font-size: 0px;
        cursor: pointer;
      }
      .standfirst {
        width: 0px;
      }
      .title {
        font-size: 0px;
      }
      @media screen and (max-width: ${BRAND.media.small}px) {
        .standfirst {
          display: flex;
          flex-direction: row;
          width: 240px;
          justify-content: space-between;
          align-items: center;
        }
        .close {
          padding-bottom: 6px;
          font-size: 18px;
        }
        .title {
          color: ${BRAND.primaryColour};
          font-weight: 600;
          margin-bottom: 12px;
          font-size: 18px;
          line-height: 24px;
        }
      }
    `}</style>
  </div>
)

const Menu = ({ links, title }) => (
  <ul className='unordered-list'>
    <CloseTitle title={title} />
    <br />
    <div
      style={{
        maxWidth: `${BRAND.layout.content}px`,
        width: '100%',
        scrollBehavior: 'smooth',
        overflow: 'auto'
      }}>
      <section className='direction'>
        {links?.map((link, i) => (
          <li className='links-list' key={`link-list-${i}`}>
            <h5 className='links-header'>{link.title}</h5>
            {link?.link?.map(({ url = '', title = '' }, index) => (
              <a className='anchor' key={`link-anchor-${i}`} href={normaliseLink(url)}>
                {title}
              </a>
            ))}
          </li>
        ))}
      </section>
    </div>
    <style jsx>
      {`
        .direction {
          display: flex;
          flex-direction: row;
        }
        .unordered-list {
          min-height: 420px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          align-items: flex-start;
          padding: 24px 36px 48px;
          list-style: none;
        }
        .anchor {
          font-size: 16px;
          font-weight: 500;
          margin: 6px 0px;
          text-decoration: none;
          cursor: pointer;
        }
        .links-header {
          font-weight: 600;
          font-size: 18px;
          margin: 6px 0px;
        }
        .links-list {
          padding-right: 64px;
          min-width: 220px;
          display: flex;
          margin-bottom: 32px;
          flex-direction: column;
        }

        @media screen and (max-width: ${BRAND.media.average}px) {
          .links-list {
            min-width: 140px;
            padding-right: 48px;
          }
        }
        @media screen and (max-width: ${BRAND.media.medium}px) {
          .links-list {
            min-width: 130px;
            padding-right: 36px;
          }
          .anchor {
            font-size: 14px;
          }
          .links-header {
            font-size: 16px;
          }
        }
        @media screen and (max-width: ${BRAND.media.small}px) {
          .direction {
            display: flex;
            flex-direction: column;
          }
          .ordered-list {
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 24px 24px 72px;
            width: 100%;
            max-height: calc(100vh - 100px);
            overflow: auto;
          }
        }
      `}
    </style>
  </ul>
)

const DropDown = ({ menu }) => {
  if (!menu) return null
  return (
    <div className='drop-down-container'>
      <Menu links={menu.links} title={menu.title} />
      <style jsx>{`
        .drop-down-container {
          width: 100%;
          min-height: 704px;
          left: 0;
          top: 100px;
          background-color: white;
          position: absolute;
          border: 1px solid #f1f1f1;
          z-index: 1000;
        }

        @media screen and (max-width: ${BRAND.media.small}px) {
          .drop-down-container {
            width: 300px;
          }
        }
      `}</style>
    </div>
  )
}

const MegaMenu = ({ megaMenu, isHomepage = false }) => {
  if (!megaMenu) {
    return <ErrorMessage />
  }

  const menu = useRef()

  const menus = megaMenu.menus.filter(_.identity)

  const [activeMegaMenu, setActiveMegaMenu] = useState(null)

  useClickOutside(menu, () => {
    setActiveMegaMenu(null)
  })

  const handleOption = (option) => {
    if (activeMegaMenu && option.title === activeMegaMenu.title) {
      setActiveMegaMenu(null)
      return
    }

    setActiveMegaMenu(option)
  }

  return (
    <div height='40' className={activeMegaMenu ? 'mega-menu-selected' : 'mega-menu'} id='mega-menu-id'>
      <nav className='nav' ref={menu}>
        <ul className='unordered-list' onClick={() => setActiveMegaMenu(null)}>
          {menus?.filter(_.identity).map((menu, menuKey) => (
            <li tabIndex={0} className='menu-list-item' key={`menu-list-item-${menuKey}`}>
              <SectionTitle
                {...{ title: menu.title, activeMegaMenu, isHomepage }}
                handleClick={() => handleOption(menu)}
              />
            </li>
          ))}
        </ul>
        <DropDown menu={activeMegaMenu} />
      </nav>
      <style jsx>
        {`
          .mega-menu {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 36px;
            background-color: ${isHomepage ? 'transparent' : 'white'};
            border: 1px solid ${isHomepage ? '#f5f5f51a' : '#f1f1f1'};
            z-index: 100;
            position: absolute;
          }
          .mega-menu-selected {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 36px;
            background-color: #ffffff;
            border: 1px solid #f5f5f51a;
          }
          .unordered-list {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            background-color: transparent;
            gap: 30px;
            list-style: none;
            padding: 0;
            z-index: 400;
          }
          .menu-list-item {
            display: flex;
            z-index: 500;
          }
          .nav {
            background-color: transparent;
            max-width: calc(${BRAND.layout.content}px + 64px);
            width: 100%;
            padding: 0 32px;
            overflow-x: auto;
          }
          @media screen and (max-width: ${BRAND.media.average}px) {
            .list {
              margin: 0px 16px 0px 0px;
            }
          }
          @media screen and (max-width: ${BRAND.media.small}px) {
            .unordered-list {
              width: 90vw;
              align-items: flex-start;
            }
            .mega-menu {
              width: 100%;
            }
            .menu-list-item {
              margin: 0px 10px 0px 0px;
            }
            .nav {
              background-color: transparent;
              padding: 0 24px;
            }
          }
        `}
      </style>
    </div>
  )
}

export default MegaMenu
