export const Modal = ({ children }) => (
  <section className='modal'
  >
    {children}
    <br />
    <style jsx>{`
      .modal {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 24px 0px;
      }
    `}</style>
  </section>
)

export default Modal
