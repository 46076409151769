import Head from 'next/head'
import { BRAND } from '../constants'
import { Layout } from '../components/Layout'
import { TargetContent, HowTo, PrimaryCTA } from '../components/UI'
import { externalImageLoader } from '../utilities/externalImageLoader'
import {
  Announcements,
  Hero,
  StatementBox,
  BuisnessBanner,
  VenueBanner,
  Testimonials
} from '../components/HomePage'
import { Gallery, Meta } from '../components/Landing'
import { ListicleContentBlock } from '../components/Landing/Listicle/ContentBlock/ListicleContentBlock'
import getCallToActionUrl from '../utilities/getCallToActionURL'
import Image from 'next/image'

const canonicalURLSuffix = {
  GB: '/',
  AU: '/au',
  US: '/us'
}

const formatCanonicalHref = (href, localeString) => {
  const suffix = canonicalURLSuffix[localeString] ? canonicalURLSuffix[localeString] : '/'
  return `https://${href}${suffix}`
}

export const HomePage = ({
  host,
  megaMenu,
  homePage,
  targetLocations,
  targetVenues,
  targetEvents,
  targetBlogs,
  footer,
  spaceCard,
  spaces,
  primaryCta,
  locale
}) => {
  const {
    primaryContent,
    secondaryContent,
    linkingBlock1,
    linkingBlock2,
    linkingBlock3,
    galleryEnabled,
    productBlock1,
    productBlock1Link,
    productBlock2,
    productBlock2Link,
    blurbCta,
    testimonialsHeadline,
    testimonial
  } = homePage
  const callToActionURL = getCallToActionUrl({ locale })

  return (
    <div className='hiddenOverflow' style={{ zIndex: '0' }}>
      <Layout {...{ navType: 'STANDARD', megaMenu, footer, isHomepage: true }}>
        <Head>
          <link rel='canonical' href={formatCanonicalHref(host, locale)} />
          <link rel='alternate' href={`https://${host}/`} hrefLang='en-gb' />
          <link rel='alternate' href={`https://${host}/au`} hrefLang='en-au' />
          <link rel='alternate' href={`https://${host}/us`} hrefLang='en-us' />
          <link rel='alternate' href={`https://${host}/ie`} hrefLang='en-gb' />
          <link rel='alternate' href={`https://${host}/`} hrefLang='x-default' />
        </Head>
        <Meta
          metaTitle='HeadBox │ Stop searching and let the perfect venue find you!'
          metaDescription={`Get fast, personalised responses from available venues that match what you’re looking for. No long searches, no hidden costs, no stress.`}
        />
        <Hero {...{ homePage, callToActionURL }} style={{ zIndex: '0' }} />
        {homePage.statementBox.length > 0 && <StatementBox {...{ homePage }} />}
        <div className='container'>
          {linkingBlock1 && targetEvents && <TargetContent target={targetEvents} />}
          {productBlock1 && <BuisnessBanner link={productBlock1Link} locale={locale} />}
          {testimonial && (
            <Testimonials testimonialsHeadline={testimonialsHeadline} testimonials={testimonial} />
          )}
          {primaryContent && <ListicleContentBlock type='primary' contentBlock={primaryContent} />}
          {linkingBlock2 && targetVenues && <TargetContent target={targetVenues} />}
          {secondaryContent && <ListicleContentBlock type='secondary' contentBlock={secondaryContent} />}
          {productBlock2 && <VenueBanner link={productBlock2Link} />}
          {linkingBlock3 && targetLocations && <TargetContent target={targetLocations} />}
          {targetBlogs && <TargetContent target={targetBlogs} />}
          <br />
          {galleryEnabled && spaces.length > 0 && (
            <>
              <h2 className='spaces' style={{ marginBottom: 0, marginTop: 20 }}>
                Spaces to inspire
              </h2>
              <Gallery {...{ spaceCard, spaces, locale }} />
            </>
          )}
          <PrimaryCTA {...{ primaryCta, homePage, blurbCta }} />
        </div>
      </Layout>
      <style jsx>{`
        .div {
          width: 100px;
          height: 100px;
          color: hotpink;
        }
        .carousel-next {
          filter: drop-shadow(0px 1px 2px #4a4a4a);
          width: 40px;
          height: 40px;
          padding: 20px;
          background-color: transparent;
          border: none;
          outline: none;
        }
        .container {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }
        .spaces {
          font-size: 32px;
          color: #4a4c49;
        }
        @media screen and (max-width: ${BRAND.media.medium}px) {
          .anchor {
            font-size: 16px;
          }
          .icon {
            width: 9px;
            height: 9px;
            padding: 0px 6px 0px 0px;
          }
          .breadcrumb {
            font-size: 16px;
          }
        }
        @media screen and (max-width: ${BRAND.media.tablet}px) {
          .spaces {
            font-size: 28px;
            line-height:1.3;
          }
        }
          @media screen and (max-width: ${BRAND.media.small}px) {
            .spaces {
              font-size: 24px;
            }
          }
        }
      `}</style>
    </div>
  )
}
