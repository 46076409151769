import Image from 'next/image'
import { useState } from 'react'
import { getAuthorUrl } from '../../utilities/getAuthorUrl'
import { STRAPI_IMGX } from '../../constants'
import { externalImageLoader } from '../../utilities/externalImageLoader'

export const AuthorNames = ({ authors, baseUrl, authorId }) => {
  const [hoveredAuthorId, setHoveredAuthorId] = useState(null)

  const handleMouseEnter = (authorId) => {
    setHoveredAuthorId(authorId)
  }

  const handleMouseLeave = () => {
    setHoveredAuthorId(null)
  }

  const renderImage = (author) => {
    return `${STRAPI_IMGX}/${author.hash + author.ext}?fit=facearea&facepad=4&w=400&h=400`
  }

  return (
    <>
      <div style={{ padding: '0', margin: '0' }}>
        <div>
          {authors.map((author) => (
            <a
              href={
                author.id == authorId
                  ? null
                  : getAuthorUrl(author.id, author.firstName, author.lastName, author.locale, baseUrl)
              }
              style={{ position: 'relative', zIndex: 2 }}>
              <div
                key={author.id}
                className={`authorImageContainer ${hoveredAuthorId === author.id ? 'hovered' : ''}`}
                onMouseEnter={() => handleMouseEnter(author.id)}
                onMouseLeave={handleMouseLeave}
                style={{
                  borderRadius: '50%',
                  width: '50px',
                  height: '50px',
                  overflow: 'hidden',
                  display: 'inline-block'
                }}>
                <Image
                  src={renderImage(author)}
                  loader={externalImageLoader}
                  alt={`${author.firstName} ${author.lastName}`}
                  width='50'
                  height='50'
                  style={{ objectFit: 'cover' }}
                />
              </div>
            </a>
          ))}
        </div>

        <ul style={{ padding: '0', margin: '0' }}>
          {authors.map((author, i) => (
            <li style={{ display: 'inline' }}>
              <span className={'authorName'}>
                {i === authors.length - 1 && authors.length !== 1 && ' and '}
              </span>
              <a
                style={{ textDecoration: 'none', marginLeft: '0px', position: 'relative', zIndex: 2 }}
                href={
                  author.id == authorId
                    ? null
                    : getAuthorUrl(author.id, author.firstName, author.lastName, author.locale, baseUrl)
                }>
                <span
                  key={author.id}
                  className={`authorName ${hoveredAuthorId === author.id ? 'hovered' : ''}`}
                  onMouseEnter={() => handleMouseEnter(author.id)}
                  onMouseLeave={handleMouseLeave}>
                  {author.firstName} {author.lastName}
                </span>
              </a>
              <span className={'authorName'}>{i !== authors.length - 1 && authors.length !== 2 && ', '}</span>
            </li>
          ))}
        </ul>
      </div>

      <style jsx>{`
        .authorImageContainer {
          border: 2px solid transparent;
          transition: border-color 0.3s ease;
        }

        .authorImageContainer.hovered {
          border-color: #af231c;
        }

        .authorName {
          color: #1f1f1f;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
        }

        .authorDisabled {
          pointer-events: none;
          cursor: default;
          text-decoration: none;
        }

        .authorName.hovered {
          color: #af231c;
          transition: color 0.3s ease;
        }
      `}</style>
    </>
  )
}
