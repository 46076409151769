import React from 'react'

const ArrowSvg = ({ color }) => {
  return (
    <svg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.49854 6.00057L0.499512 11.0774V10.7686L6.515 6.4045L7.07285 5.99979L6.515 5.59508L0.499512 1.23093V0.92372L7.49854 6.00057Z'
        fill={color}
        stroke={color}
      />
    </svg>
  )
}

export default ArrowSvg
