export const LANGUAGES = new Map([
  ['gb', 'en-gb'],
  ['au', 'en-au'],
  ['us', 'en-us']
])

export const LOCALES = new Map([
  ['gb', 'GB'],
  ['au', 'AU'],
  ['us', 'US'],
  ['ie', 'IE']
])

const ACTIVE_LOCALES = ['GB', 'AU', 'US']

export const isActiveLocale = (locale) => ACTIVE_LOCALES.includes(locale)

export const PAGE_TYPES = new Map([
  ['ilp', 'ilp'],
  ['cp', 'cp'],
  ['hp', 'hp'],
  ['author', 'author']
])

const iPhoneSE = 325

export const BRAND = {
  primaryColour: '#AF231C',
  lightColour: '#B4B4B4',
  textColour: '#4a4c49',
  linkColour: '#008B8B',
  grey: '#F5F5F5',
  darkGrey: '#595959',
  white: '#f9f9f9',
  black: '#1f1f1f',

  layout: {
    width: 1400,
    minWidth: 350,
    content: 1290,
    narrowContent: 1000,
    sideMenu: 300
  },
  media: {
    xs: 0,
    small: 600,
    tablet: 900,
    medium: 1200,
    average: 1400,
    large: 1800
  },
  card: {
    minWidth: iPhoneSE,
    mediumWidth: 350,
    dimension: 310,
    padding: 32,
    width: 380,
    height: 260
  },
  shadow: '0px 5px 15px hsla(0, 0%, 0%, 0.2)'
}

export const IMGIX = process.env.NEXT_PUBLIC_IMGIX
export const BRIEF_BUILDER = process.env.NEXT_PUBLIC_BRIEF_BUILDER
export const STRAPI_IMGX = process.env.NEXT_PUBLIC_STRAPI_IMGX
export const IMGIX_SETTINGS = `?auto=compress,format&sharp=25&fit=crop&w=${BRAND.card.width}&h=${BRAND.card.height}&w=740&h=620`
export const IMGIX_SETTINGS_MINIFIED = `?auto=compress,format&q=35&bri=15&sharp=40w=200&h=200`
export const ILPS_BASE_PATH = process.env.NEXT_PUBLIC_HEADBOX_ENDPOINT
export const ASSET_PREFIX = process.env.ASSET_PREFIX
export const ENVIRONMENT = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT

export const SOFT_LAUNCH_URLS = {}
