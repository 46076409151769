import Head from 'next/head'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import { insertDynamicText } from '../../controllers/helpers'
import Script from 'next/script'

const { nonce } = getConfig().publicRuntimeConfig

export const Meta = ({
  metaDescription,
  metaTitle,
  schema,
  host,
  displayLocation = '',
  nicheOrEventName = ''
}) => {
  const { query } = useRouter()

  const pagination = query?.page ? `?page=${query.page}` : ''
  const pageNumber = query?.page ? ` - page ${query.page}` : ''

  metaTitle = insertDynamicText(metaTitle, displayLocation, nicheOrEventName)
  metaDescription = insertDynamicText(metaDescription, displayLocation, nicheOrEventName)

  const Schema = ({ schema }) => {
    if (!schema) return null
    return (
      <Head>
        {schema?.venues && <Script type='application/ld+json'>{JSON.stringify(schema.venues)}</Script>}
        {schema?.faqs && <Script type='application/ld+json'>{JSON.stringify(schema.faqs)}</Script>}
        {schema?.breadcrumbs && (
          <Script type='application/ld+json'>{JSON.stringify(schema.breadcrumbs)}</Script>
        )}
        {schema?.article && <Script type='application/ld+json'>{JSON.stringify(schema.article)}</Script>}
        {schema?.author && <Script type='application/ld+json'>{JSON.stringify(schema.author)}</Script>}
      </Head>
    )
  }

  return (
    <>
      <Head>
        <title>{`${metaTitle}${pageNumber}`}</title>
        <meta property='og:title' content={metaTitle} key='title' />
        {!!host && <link rel='canonical' href={`https://${host}/${query.slug.join('/')}${pagination}`} />}
        <meta name='description' content={`${metaDescription}${pageNumber}`} />
      </Head>
      <Schema schema={schema} />
    </>
  )
}
