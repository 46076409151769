import { BRAND } from '../../constants'
import { externalImageLoader } from '../../utilities/externalImageLoader'
import { ButtonLink } from '../UI'
import { STRAPI_IMGX } from '../../constants'
import Image from 'next/image'

const renderImage = (heroBackgroundImage) => {
  return `${STRAPI_IMGX}/${
    heroBackgroundImage.hash + heroBackgroundImage.ext
  }?auto=format&h=500&w=1500&fit=crop`
}

const StatementBox = ({ homePage }) => {
  const { statementBox } = homePage

  return (
    <>
      <section className='statement-box-container'>
        {statementBox.map((box) => (
          <section className='statement-box' key={box.id}>
            <Image
              alt='Events made easy at HeadBox'
              src={box.url}
              aria-hidden='true'
              style={{ objectFit: 'cover' }}
              width={20}
              height={20}
              className='img'
            />
            <b className='headline'>{box.headline}</b>
            <p className='body-copy'>{box.body_copy}</p>
          </section>
        ))}
      </section>

      <style jsx>{`
        .statement-box-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          gap: 20px; /* Space between boxes */
          padding: 60px 0px; /* Default padding for desktop */
          background-color: #f9f9f9;
        }

        .statement-box {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          background: white;
          border: 1px solid #ddd;
          border-radius: 8px;
          padding: 20px;
          line-height: 22px;
          width: 390px;
        }

        .img {
          border-radius: 8px;
          width: 100%; /* Ensures the image scales to fit box width */
          height: auto;
          margin-bottom: 15px; /* Space between image and headline */
        }

        .headline {
          font-size: 20px;
          color: rgba(31, 31, 31, 1);
          font-weight: 800;
          padding-top: 8px;
          padding-bottom: 8px;
        }

        .body-copy {
          font-size: 16px;
          font-weight: 400;
          color: rgba(31, 31, 31, 1);
        }

        @media (max-width: 768px) {
          .statement-box-container {
            padding: 60px 16px; /* Add 16px side padding for mobile */
          }
        }
      `}</style>
    </>
  )
}

export default StatementBox
