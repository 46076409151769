import React from 'react'

const StarSvg = ({ starFillColor }) => {
  return (
    <svg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.79741 15.4434L5.35463 18.1568L6.56247 13.0927C6.68209 12.5911 6.51108 12.0648 6.1195 11.7294L2.16708 8.34376L7.35525 7.92811C7.86926 7.88693 8.31702 7.56161 8.51503 7.08549L10.5141 2.27866L12.5132 7.08549C12.7112 7.56162 13.1589 7.88693 13.673 7.9281L18.8619 8.34376L14.9088 11.7293C14.5172 12.0648 14.3461 12.5911 14.4657 13.0927L15.6736 18.1568L11.2308 15.4434C10.7908 15.1747 10.2374 15.1747 9.79741 15.4434ZM19.5469 7.75715L19.5464 7.75751L19.5469 7.75715Z'
        fill={starFillColor}
        stroke='#D7918E'
        stroke-width='1.75'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export default StarSvg
