import React from 'react'

import GlobeSvg from './GlobeSvg'
import StarSvg from './StarSvg'
import { BRAND } from '../../constants'

const Testimonials = ({ testimonialsHeadline, testimonials }) => {
  return (
    <>
      <div className='testimonial-title '>{testimonialsHeadline}</div>
      <section className='tesimonials-container'>
        <div className='tesimonials-scroll'>
          <div className='tesimonials-scroll__wrapper'>
            {testimonials.map((testimonial, index) => (
              <a
                target='_blank'
                rel='noopener noreferrer'
                href={testimonial.link}
                key={index}
                className='testimonial-card'>
                <section>
                  <div className='stars'>
                    {Array.from({ length: 5 }).map((_, index) => (
                      <StarSvg
                        key={index}
                        starFillColor={index < testimonial.numberStars ? '#D7918E' : 'white'}
                      />
                    ))}
                  </div>
                  <div className='headline title-clip '>{testimonial.headline}</div>
                  <div className='text-clip body-copy'>{testimonial.bodyCopy}</div>
                </section>

                <div className='footer'>
                  <div>{testimonial.firstName}</div>
                  <div className='locale'>
                    <span className='globe'>
                      <GlobeSvg />
                    </span>
                    <div className='testimonial-name'> {testimonial.locale}</div>{' '}
                  </div>
                </div>
              </a>
            ))}
          </div>
        </div>
      </section>

      <style jsx>{`
        .testimonial-title {
          font-family: Montserrat;
          font-weight: 700;
          font-size: 32px;
          line-height: 40px;
          letter-spacing: -0.005em;
          text-align: center;
          padding-top: 36px;
        }

        @media screen and (max-width: ${BRAND.media.small}px) {
          .testimonial-title {
            font-size: 24px;
            line-height: 30px;
          }
        }

        @media screen and (max-width: 450px) {
          .testimonial-title {
            width 50%;
            font-size: 24px;
            line-height: 30px;
            min-width: 180px;
          }
        }

        .tesimonials-container {
          display: flex;               
          flex-direction: column;     
          margin: auto;                
          max-width: 1208px;            
          padding: 24px 0px 24px 0px;
        }

        .tesimonials-scroll {
          display: flex;              
          overflow: auto;            /* Hides overflow to create infinite scroll effect */
          flex-wrap: nowrap; 
          user-select: none;           
        }

        .tesimonials-scroll::-webkit-scrollbar {
          display: none;
        }

        .tesimonials-scroll__wrapper {
          flex-shrink: 0;              
          display: flex;               
          align-items: center;         
          justify-content: space-around; /
          min-width: 100%;            
        }

        @media (max-width: 1470px) {
         .tesimonials-container {
         max-width: 918px;
         }
        }

        @media (max-width: 1115px) {
         .tesimonials-container {
         max-width: 615px;
         }
        }

        @media (max-width: 750px) {
         .tesimonials-container {
         max-width: 615px;
         }
        }

        @media (max-width: 608px) {
         .tesimonials-container {
         max-width: 308px;
         }
        }

        .text-clip {
          display: -webkit-box;
          -webkit-line-clamp: 5; /* Number of lines you want to display */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
        }

        .title-clip {
          display: -webkit-box;
          -webkit-line-clamp: 3; /* Number of lines you want to display */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
        }

        .testimonial-card {
          text-decoration: none; 
          display: flex;
          flex-direction: column;
          border: 1px solid #ddd;
          border-radius: 10px;
          padding: 20px;
          margin: 8px;
          max-width: 288px;
          height: 314px;  
          background-color: #fff;
          justify-content: space-between;
        }

        .stars {
          display: flex;
          gap: 3px;
          margin-bottom: 10px;
        }

        .headline {
          font-weight: bold;
          color: #262626;
          font-size: 20px;
          weight: 800px
          line-height: 24px;
          margin-bottom: 10px;
        }

        .body-copy {
          color: #262626;
          font-size: 16px;
          margin-bottom: 15px;
          weight: 400px
          line-height: 24px;
          padding: 0;
          margin: 0;
        }

        .footer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
          font-size: 14px;
          weight: 600;
          color: #595a5a;
        }

        .locale {
          display: flex;
          align-items: center;
        }

        .globe {
          font-size: 18px;
          margin-right: 5px;
          padding-top: 5px;
        }

      `}</style>
    </>
  )
}

export default Testimonials
