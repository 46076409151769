import { BRAND } from '../../constants'
import { externalImageLoader } from '../../utilities/externalImageLoader'
import { ButtonLink } from '../UI'
import { STRAPI_IMGX } from '../../constants'
import Image from 'next/image'

const CTAButtons = ({ homePage }) => {
  const { heroCTAButton } = homePage

  return (
    <>
      <section className='cta-buttons'>
        {heroCTAButton.map((cta) => (
          <ButtonLink href={cta.link} cta>
            {cta.button_text}
          </ButtonLink>
        ))}
      </section>

      <style jsx>{`
        .cta-buttons {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          gap: 20px; /* Space between boxes */
          padding: 64px 20px 0px 20px; /* Padding around the container */
        }

        @media screen and (max-width: 550px) {
          .cta-buttons {
            padding: 20px;
          }
      `}</style>
    </>
  )
}

export default CTAButtons
