import ReactMarkdown from 'react-markdown'
import { BRAND } from '../../../../constants'
import { renderImage } from '../../../../controllers/helpers/renderImage'
import { Carousel } from '../../../Carousel/Carousel'
import { ListicleCard } from '../Related/ListicleCard'
import { PrimaryContentBlockCard } from './PrimaryContentBlockCard'
import { listicleContentBlockComponents } from '../../../../utilities/markdownOperations'

export const ListicleContentBlock = ({ contentBlock, type }) => {
  if (!contentBlock || !contentBlock.listicles || contentBlock.listicles.length === 0) return null

  const { title, blurb, listicles } = contentBlock

  const listiclePreviews = listicles.map((listicle) => ({
    imgSrc: listicle.cover ? renderImage(listicle.cover) : '',
    title: listicle.title,
    description: listicle.introduction,
    url: listicle.path,
    alt: listicle.cover ? listicle.cover.alternativeText : ''
  }))

  return (
    <section className='container'>
      <h3 id='featured-inspiration' className='title'>
        {title}
      </h3>
      {blurb && (
        <div className='blurb'>
          <ReactMarkdown components={listicleContentBlockComponents}>{blurb}</ReactMarkdown>
        </div>
      )}
      <Carousel
        headingId='featured-inspiration'
        size={type === 'primary' ? 'large' : 'medium'}
        slides={listiclePreviews.map((listicle, index) => {
          return type === 'primary' ? (
            <PrimaryContentBlockCard listicle={listicle} key={`listicle-${index}`} />
          ) : (
            <ListicleCard listicle={listicle} key={`listicle-${index}`} />
          )
        })}></Carousel>

      <style jsx>{`
        .container {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 64px 16px 64px;
          gap: 32px;
          background: linear-gradient(142.83deg, #434343 0.68%, #141414 100%), #ffffff;
        }

        .title {
          font-weight: 700;
          font-size: 32px;
          line-height: 40px;
          text-align: center;
          letter-spacing: -0.005em;
          color: #ffffff;
        }

        .blurb {
          display: flex;
          flex-direction: column;
          gap: 1em;
          text-align: center;
          text-wrap: balance;
          max-width: 850px;
          margin-top: -16px;
        }

        .carousel-margin {
          margin-top: 16px;
          width: 100%;
        }

        @media screen and (max-width: ${BRAND.media.medium}px) {
        }

        @media screen and (max-width: ${BRAND.media.small}px) {
          .title {
            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
          }
        }
      `}</style>
    </section>
  )
}
